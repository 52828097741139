/***** fa color *****/
.fa-color-orang,
.fa-color-rss {
  color: #ff6600;
}
a:hover .fa-color-orang,
a:active .fa-color-orang,
a:hover .fa-color-rss,
a:active .fa-color-rss {
  color: #EE5500;
}

.fa-color-dailymotion {
  color: #00d2f3;
}
a:hover .fa-color-dailymotion,
a:active .fa-color-dailymotion {
  color: #17afc6;
}

.fa-color-twitter {
  color: #000000;
}
a:hover .fa-color-twitter,
a:active .fa-color-twitter {
  color: #000000;
}

.fa-color-facebook {
  color: #3b5998;
}
a:hover .fa-color-facebook,
a:active .fa-color-facebook {
  color: #304777;
}

.fa-color-google-plus {
  color: #dd4b39;
}
a:hover .fa-color-google-plus,
a:active .fa-color-google-plus {
  color: #B43C2E;
}

.fa-color-linkedin {
  color: #007bb6;
}
a:hover .fa-color-linkedin,
a:active .fa-color-linkedin {
  color: #016DA2;
}

.fa-color-pinterest {
  color: #cb2027;
}
a:hover .fa-color-pinterest,
a:active .fa-color-pinterest {
  color: #9B080E;
}

.fa-color-youtube {
  color: #bb0000;
}
a:hover .fa-color-youtube,
a:active .fa-color-youtube {
  color: #8A0000;
}

.fa-color-tumblr {
  color: #32506d;
}
a:hover .fa-color-tumblr,
a:active .fa-color-tumblr {
  color: #102941;
}

.fa-color-vimeo {
  color: #45bbff;
}
a:hover .fa-color-vimeo,
a:active .fa-color-vimeo {
  color: #2479AA;
}

.fa-color-flickr {
  color: #ff0084;
}
a:hover .fa-color-flickr,
a:active .fa-color-flickr {
  color: #9E0956;
}

.fa-color-windows {
  color: #00BCF2;
}
a:hover .fa-color-windows,
a:active .fa-color-windows {
  color: #037DA0;
}

.fa-color-android {
  color: #95CE64;
}
a:hover .fa-color-android,
a:active .fa-color-android {
  color: #538329;
}

.fa-color-instagram {
  color: #517fa4;
}
a:hover .fa-color-instagram,
a:active .fa-color-instagram {
  color: #214461;
}

.fa-color-github {
  color: #333;
}
a:hover .fa-color-github,
a:active .fa-color-github {
  color: #000;
}





