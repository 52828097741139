@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);

html,
body,
.pac-container {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

html,
body {
  height: 100%;
  position: relative;
}

/**
 * Utilities.
 */

.bg-white {
  background-color: #fff;
}

.bg-light {
  background-color: #F6F7FD;
}

.bg-sgrey {
  background-color: #696B70;
}

.bg-white-corner {
  background-image: url("../../images/white-corner.png");
  height: 110px;
  opacity: 0.7;
  width: 110px;
}

.select-for-dl-active .bg-white-corner,
.select-for-dl-active .bg-white-overlay {
  background-image: none;
  opacity: 0.85;
}

.select-for-dl-active .bg-white-corner {
  background-image: none;
  border-style: solid;
  border-width: 0 110px 110px 0;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  height: 0;
  line-height: 0px;
  width: 0;
}

.select-for-dl-active .fa {
  color: #fff;
}

.bg-white-corner.sm {
  height: 80px;
  width: 80px;
}

.bg-white-overlay {
  background-image: url("../../images/background-white.png");
  height: 100%;
  width: 100%;
}

.text-unstyled,
a.text-unstyled,
a.text-unstyled:hover {
  color: #333;
}

.text-xxs {
  font-size: 10px;
}

.text-xs,
.text-xsmall {
  font-size: 12px;
}

.text-sm,
.text-small {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

.l-h-md {
  line-height: 32px;
}

.l-h-xxl {
  line-height: 75px;
}

.btn,
.table th {
  letter-spacing: 0.05em;
}

.text-upper,
.btn {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.text-white {
  color: #fff;
}

a.text-white .wizicon path {
  fill: #ffffff;
}

.round0,
.thumbnail,
.panel.panel-light,
.popover,
.popover-title,
.popover-content,
.dropdown-menu,
.btn-sharp {
  -webkit-border-radius: 0px;
  border-radius: 0px;
}

.round {
  -webkit-border-radius: 500px;
  border-radius: 500px;
}

.no-shadow,
.dropdown-menu,
.form-control,
.input-group input[type='text'],
.panel,
.popover,
.btn.btn-link.active {
  -o-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 480px) {
  .pull-none-xs {
    float: none !important;
  }
}

@media (max-width: 766px) {
  .pull-none-sm {
    float: none !important;
  }
}

@media (max-width: 968px) {
  .pull-none-md {
    float: none !important;
  }
}

.m-md {
  margin: 15px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.p-0 {
  padding: 0 !important;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-xxl {
  margin-left: 50px;
}

.p-xs {
  padding: 5px !important;
}

.p-sm {
  padding: 10px !important;
}

.p-md {
  padding: 15px !important;
}

.p-lg {
  padding: 30px !important;
}

.p-t-0 {
  padding-top: 0px !important;
}

.p-t-xs {
  padding-top: 5px !important;
}

.p-t-sm {
  padding-top: 10px !important;
}

.p-t-md {
  padding-top: 20px !important;
}

.p-t-lg {
  padding-top: 30px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-xs {
  padding-bottom: 5px !important;
}

.p-b-sm {
  padding-top: 10px !important;
}

.p-b-md {
  padding-bottom: 20px !important;
}

.p-l-sm {
  padding-left: 10px !important;
}

.p-l-xxl {
  padding-left: 90px !important;
}

.p-r-sm {
  padding-right: 10px !important;
}

.p-r-md {
  padding-right: 20px !important;
}

.p-r-lg {
  padding-right: 30px !important;
}

.w-sm {
  width: 90px;
}

.thumb-sm {
  width: 40px;
}

.icon-xs {
  width: 14px;
}

.icon-xs {
  width: 20px;
}

.icon-md {
  width: 38px;
}

.b-t {
  border-top: 1px solid #e4e9ec;
}

.b-0 {
  border: 0;
}

.b-b {
  border-bottom: 1px solid #e4e9ec;
}

.b-l {
  border-left: 1px solid #e4e9ec;
}

.b-r {
  border-right: 1px solid #e4e9ec;
}

.b-2x {
  border-width: 2px;
}

.b-3x {
  border-width: 3px;
}

.b-dashed {
  border-style: dashed;
}

/**
 * Layout.
 */

.row-space0,
.row-space1,
.row-space2,
.row-space3,
.row-space-0,
.row-space-1,
.row-space-2,
.row-space-3,
.row-space20,
.row-space30 {
  display: block;
  margin-top: 0;
}

.row-space0,
.row-space-0 {
  margin-bottom: 0;
}

.row-space1,
.row-space-1 {
  margin-bottom: 0.3em;
}

.row-space2,
.row-space-2 {
  margin-bottom: 0.7em;
}

.row-space3,
.row-space-3 {
  margin-bottom: 1.3em;
}

.row-space20 {
  margin-bottom: 20px;
}

.row-space30,
.thumbnail {
  margin-bottom: 30px;
}

.section-top-sm {
  padding-top: 20px;
}

.section-top {
  padding-top: 40px;
}

.section-top-lg {
  padding-top: 65px;
}

.section-bottom {
  padding-bottom: 40px;
}

.section-bottom-sm {
  padding-bottom: 20px;
}

.d-b {
  display: block;
}

.d-ib {
  display: inline-block;
}

.d-if {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.hbox {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.hbox .col,
.hbox .col-top,
.hbox .col-middle,
.hbox .col-bottom {
  display: table-cell;
  height: 100%;
  float: none;
}

.table > tbody > tr > td.col,
.table > tbody > tr > td.col-top,
.hbox .col,
.hbox .col-top {
  vertical-align: top;
}

.table > tbody > tr > td.col-middle,
.hbox .col-middle {
  vertical-align: middle;
}

.table > tbody > tr > td.col-bottom,
.hbox .col-bottom {
  vertical-align: bottom;
}

.width-sm {
  width: 120px;
}

.centered {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
 * BUTTONS.
 */

.btn {
  font-size: 12px !important;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary.btn-facebook,
.btn-primary.btn-twitter,
.btn-primary.btn-google,
.btn-primary.btn-linkedin,
.btn-danger,
.btn-danger.disabled,
.btn-success,
.btn-success.disabled,
.btn-sgrey {
  border-color: #fff;
  color: #fff;
}

.btn-primary:hover,
.btn-primary.btn-facebook:hover,
.btn-primary.btn-twitter:hover,
.btn-primary.btn-google:hover,
.btn-primary.btn-linkedin:hover,
.btn-danger:hover,
.btn-success:hover,
.btn-sgrey {
  border-color: #fff;
  color: #fff !important;
}

.btn-primary,
.btn-primary.btn-facebook,
.btn-primary.btn-twitter,
.btn-primary.btn-google,
.btn-primary.btn-linkedin {
  background-color: #497fdc;
}

.btn-primary:hover,
.btn-primary.btn-facebook:hover,
.btn-primary.btn-twitter:hover,
.btn-primary.btn-google:hover,
.btn-primary.btn-linkedin:hover {
 background-color: #2d6bd7;
}

.btn-primary.disabled {
  background-color: #749de4;
}

.btn-danger {
  background-color: #be1922;
}

.btn-danger.disabled {
  background-color: #eb636b;
}

.btn-danger:hover {
  background-color: #91131a;
}

.btn-success {
  background-color: #6ccf5f;
}

.btn-success:hover {
  background-color: #4ac13a;
}

.btn-success.disabled {
  background-color: #a8e3a0;
}

.btn-sgrey,
.btn-group .btn-sgrey:active,
.btn-group .btn-sgrey.active {
  background-color: #7f7f87;
}

.btn-sgrey.disabled,
.btn-group .btn-sgrey {
  background-color: #c0c0c0;
}

a:focus,
a:hover,
.btn-link:hover,
.btn-link:focus{
  text-decoration: none;
  outline: 0;
}

.text-info,
.btn-link.btn-link-primary,
.btn-group .btn.btn-link.btn-link-default:active,
.btn-group .btn.btn-link.btn-link-default.active {
  color: #497fdc;
}

.text-success,
.btn-link.btn-link-success,
.btn-link.btn-link-status.active,
.btn-link.btn-link-enable.active {
  color: #6ccf5f;
}

.text-danger,
.fa.fa-trash,
.btn-link.btn-link-danger,
.btn-link.btn-link-status,
.btn-link.btn-link-enable {
  color: #be1922;
}

.btn.btn-link {
  min-width: 0;
  padding: 0;
  white-space: normal;
}

.btn-link.disabled {
  opacity: 0.3;
  cursor: default;
}

.btn-link.btn-link-default {
  color: #7f7f87;
}

.btn-group .btn-link.btn-link-default:active,
.btn-group .btn-link.btn-link-default.active {
  font-weight: bold;
}

.btn-link-status:before {
    content: "offline";
}

.btn-link-status.active:before {
  content: "online";
}

/**
 * Wizicons / Font Awesome
 *
 */

[class^="fa-"]:before,
[class*=" fa-"]:before {
  line-height: 0.6em;
  vertical-align: middle;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
}

.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-fw {
  width: 1.28571429em;
  text-align: center;
}
.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.fa-li.fa-lg {
  left: -1.85714286em;
}
.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  -webkit-border-radius: .1em;
          border-radius: .1em;
}

.fa-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
          filter: none;
}

.fa-stack {
  position: relative;
  -webkit-transform: translateY(-10%);
      -ms-transform: translateY(-10%);
          transform: translateY(-10%);
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack.fa-lg .fa-stack-1x {
  line-height: 2.3em;
}

.fa-stack-1x {
  line-height: 2.4em;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fa-wizfolder {
  opacity: 1 !important;
}

.fa-mail-sent:before,
.fa-mail-open:before,
.fa-mail-click:before {
  line-height: 1.6em !important;
}

/**
 * Navbars.
 */

.navbar.navbar-default {
  background-color: #f6f7fd;
  border-color: #e6eaed;
}

.navbar.navbar-static-bottom {
  z-index: 1000;
  border-width: 1px 0 0 0;
  margin-bottom: 0;
}

.navbar-brand {
    height: 50px;
    line-height: 50px; /* For vertical-align to work.*/
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-brand > img {
  display: inline-block;
  height: 35px;
  line-height: 100%;
  vertical-align: middle;
}

@media screen and (max-width: 766px) {
  .navbar-brand {
    float: none;
    display: block;
    margin: auto;
    padding: 0;
    width: 105px;
  }
  .navbar.navbar-default {
    position: fixed;
    width: 100%;
  }
}

.navbar.navbar-default.navbar-footer .navbar-brand {
  float: none;
  display: block;
  margin: auto;
  padding: 0;
  width: 105px;
}

.nav.nav-xs > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav.nav-xxs > li > a {
  padding-top: 0;
  padding-bottom: 0;
}

/**
 * Sidebars.
 */

/*.sidebar-offcanvas {
  border: 0px solid #e4e9ec;
  background: #f6f7fd;
}

.sidebar-offcanvas.sidebar-offcanvas-left {
  border-right-width: 1px;
}

.sidebar-offcanvas.sidebar-offcanvas-right {
  border-left-width: 1px;
}

#doubleburger-toggle-left {
  margin-left: 0px;
}
#doubleburger-toggle-right {
  margin-right: 0px;
}
#doubleburger-toggle-left:hover,
#doubleburger-toggle-left:focus,
#doubleburger-toggle-right:hover,
#doubleburger-toggle-right:focus {
  background: none;
}*/

/**
 * Thumbnails.
 */

.thumbnail {
  background-color: transparent;
  border: none;
  padding: 0;
}

.thumbnail .resource-wrapper {
  position: relative;
}

.thumbnail .resource-wrapper.img-cropped {
  height: 280px;
  overflow: hidden;
}

.thumbnail .resource-wrapper.cropped > img {
  margin-top: -8%;
}

@media (max-width: 480px) {
  .thumbnail .resource-wrapper.img-cropped {
    height: 200px;
  }
}

.thumbnail .overlay {
  line-height: 1em;
  position: absolute;
  z-index: 300;
}

.thumbnail .overlay:before {
  line-height: inherit;
  vertical-align: top;
}

.thumbnail .overlay.overlay-tl {
  left: 0%;
  top: 0%;
}

.thumbnail .overlay.overlay-tl-offset {
  left: 8px;
  top: 10px;
}

.thumbnail .overlay.overlay-tr {
  right: 0%;
  top: 0%;
}

.thumbnail .overlay.overlay-tr-offset {
  right: 8px;
  top: 10px;
}

.thumbnail .overlay.overlay-br {
  bottom: 0%;
  right: 0%;
}

.thumbnail .overlay.overlay-br-offset {
  bottom: 10px;
  right: 8px;
}

.thumbnail .overlay.overlay-br-noffset {
  bottom: -10px;
  right: -10px;
}

.thumbnail .overlay.overlay-bl {
  bottom: 0%;
  left: 0%;
}

.thumbnail .overlay.overlay-bl-offset {
  bottom: 10px;
  left: 8px;
}

.thumbnail .overlay.overlay-bl-noffset {
  bottom: -10px;
  left: -10px;
}

.thumbnail .overlay.overlay-center {
  bottom: 0;
  height: 1em;
  left: 0;
  margin: auto;
  overflow: visible;
  right: 0;
  text-align: center;
  top: 0;
}

.overlay-full {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.thumbnail i.overlay {
  opacity: 0.8;
  padding: 0 0.05em; /* Fix for non-squared font-awesome icons. */
}

.thumbnail .caption {
  color: #7e8083;
  padding: 0;
}

.thumbnail .title,
.thumbnail .title a {
  color: #373737;
}

.list-experts .info,
.thumbnail .info {
  line-height: 1.42857143em;
}

.caption .info {
  text-align: left;
}

.avatar {
  display: block;
  position: relative;
  margin-bottom: 20px;
  max-width: 180px;
  margin-right: auto;
  margin-left: 0;
}

.avatar img {
  width: 100%;
}

img.hover {
  position: absolute;
  left:0;
  top: 0;
}

/**
 * Dropdowns.
 */

.dropdown-toggle,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  text-decoration: none;
}

.dropdown-menu {
  padding: 0;
  margin-top: 0;
  border-color: #e6eaed;
}

.dropdown-menu > li {
  border-bottom: 1px solid #e6eaed;
}

.dropdown-menu > li:last-child {
  border-bottom-width: 0;
}

.dropdown-menu > li > a {

  font-size: 0.9em;
  font-weight: bold;
  padding: 12px 15px;
}

.dropdown-menu.open > a,
.dropdown-menu.open > a:hover,
.dropdown-menu.open > a:focus {
  background-color: #fff;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #eee;
  color: #333;
}

/**
 * Forms
 */

#intrado-settings-enabled-button {
  margin-bottom: 30px;
}

.form-control {
  border-color: #e4eaec;
  height: 44px;
}

form label,
div.dataTables_filter label,
div.dataTables_length label {
  color: #666;
  font-weight: normal;
  margin-bottom: 10px;
  padding: 0 5px 0 5px;
}

form label.checkbox {
  cursor: pointer;
}

textarea.form-control {
  padding: 10px;
}

.input-group .input-group-addon {
  background-color: #fff;
}

.input-group-search .input-group-addon,
.input-group-search .input-group-addon button,
.input-group-search .input-group-addon input[type='submit'] {
  background-color: transparent;
}

.input-group .input-group-addon,
.input-group-search .input-group-addon {
  border-color: #e4eaec;
  line-height: 2;
  padding: 0;
}

.input-group .input-group-addon .fa {
  padding: 6px 14px;
}

.input-group .input-group-addon input[type="radio"],
.input-group .input-group-addon input[type="checkbox"] {
  margin: 6px 14px;
}

.input-group .input-group-addon .fa {
  color: #82858b;
}

.input-group-search .input-group-addon button,
.input-group-search .input-group-addon input[type='submit'] {
  border: none;
  background-color: transparent;
  padding: 0;
}

.input-group input[type='text'] {
  border-right: 0;
}

.input-group input[type='text']:focus {
  outline: none;
}

.input-group input[type='text']:focus + .input-group-addon {
  border-color: #66afe9;
}

.input-group-search .input-group-addon {
  color: #3666CE;
}

/**
 * Components/Labels
 */

.label {
  -webkit-border-radius: 0;
          border-radius: 0;
  font-size: 85%;
  font-weight: 500;
  text-transform: uppercase;
}

.table.table-wiz .label {
  font-size: 85%;
  margin-bottom: 5px;
}

/**
 * Utils/Tables
 */

.table.table-wiz {
  border-width: 0;
  margin-bottom: 0;
}

.table.table-wiz.table-striped > tbody > tr:nth-child(odd) {
  background-color: #f6f7fd;
}

.table> thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  font-weight: normal;
}

.table.table-wiz > thead > tr > th,
.table.table-wiz > tbody > tr > th,
.table.table-wiz > tfoot > tr > th,
.table.table-wiz > thead > tr > td,
.table.table-wiz > tbody > tr > td,
.table.table-wiz > tfoot > tr > td {
  border-color: #fff;
  font-size: 80%;
}

.table.table-wiz > thead > tr > th,
.table.table-wiz > tbody > tr > th,
.table.table-wiz > tfoot > tr > th {
  background-color: #6C6F74;
  border-color: #dddee0;
  border-top-width: 0;
  color: #fafafa;
  padding: 6px 30px 6px 12px;
  text-transform: uppercase;
  white-space: nowrap;
}

.table.table-wiz.table-wiz-primary > thead > tr > th,
.table.table-wiz.table-wiz-primary > tbody > tr > th,
.table.table-wiz.table-wiz-primary > tfoot > tr > th {
  background-color: #577ddb;
  border-color: #91ABE8;
  color: #fafafa;
}

.table.table-wiz > thead > tr > th.col-bulkactions,
.table.table-wiz > tbody > tr > th.col-bulkactions,
.table.table-wiz > tfoot > tr > th.col-bulkactions,
.table.table-wiz > thead > tr > td.col-bulkactions,
.table.table-wiz > tbody > tr > td.col-bulkactions,
.table.table-wiz > tfoot > tr > td.col-bulkactions {
  line-height: 1em;
  padding: 6px 12px;
  text-align: center;
  width: auto;
}

.table.table-wiz .col-nowrap {
  white-space: nowrap;
}

div.dataTables_info,
div.dataTables_length label {
  color: #666;
  font-size: 0.9em;
  text-transform: uppercase;
}

div.dataTables_info {
  padding-left: 10px;
}

div.dataTables_length label select {
  margin-right: 5px;
}

/**
 * PANELS.
 */

.list-inline.list-simple li + li:before {
  color: #AFAFAF;
  content: '/';
  padding-right: 12px;
}

/**
 * Modal
 */
.modal-dialog.modal-xl {
  width: 90%;
}

/**
 * TOOLTIPS.
 */

.tooltip-arrow {
  border-top-color: #222;
}

.tooltip-inner {
  background-color: #222;
  -webkit-border-radius: 2px;
          border-radius: 2px;
  max-width: 500px !important;
}

.label-default ~ .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #7c7f85;
}

.label-default ~ .tooltip .tooltip-inner {
  background-color: #7c7f85;
}

/**
 * ARROWS.
 */
.arrow {
  border-width: 15px;
  z-index: 10;
}

.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.arrow:after{
  border-width: 14px;
  content: "";
}

.arrow.top {
  left: 50%;
  margin-left: -15px;
  border-top-width: 0;
  border-bottom-color: #e4e9ec /*#F6F7FD*/ /*rgba(0, 0, 0, 0.1)*/;
  top: -14px;
}

.arrow.top.offset1 {
  left: 13%;
}

.arrow.top.offset2 {
  left: 37.5%;
}

.arrow.top.offset3 {
  left: 62%;
}

.arrow.top.offset4 {
  left: 87%;
}

.arrow.top:after {
  top: 1px;
  margin-left: -14px;
  border-top-width: 0;
  border-bottom-color: #F6F7FD;
}

/**
 * Validators Errors
 */

input.error, textarea.error {
  border: 1px solid red;
}

label.error {
  color: #ff0000;
}

/**
 * Pagination
 */

span.bg-colored {
  background-color: red !important;
}

.navigation {
  text-align: center;
}

.pagination > li > a, .pagination > li > span {
  background-color: #fff !important;
  border: 1px solid #FFF !important;
  color: #428bca;
}

.pagination > .active > span, .pagination > .active > span:hover {
  color: #717070 !important;
}

.pagination > .disabled > span, .pagination > .disabled > span:hover {
  color: #777 !important;
  cursor: not-allowed;
}

.pagination > .dt-paging-button.page-item.active > a,
.pagination > .dt-paging-button.page-item.active > span,
.pagination > .dt-paging-button.page-item.active > a:hover,
.pagination > .dt-paging-button.page-item.active > span:hover,
.pagination > .dt-paging-button.page-item.active > a:focus,
.pagination > .dt-paging-button.page-item.active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.pagination > li > input {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  border: 1px solid;
  margin: 3px 4px 5px 12px;
  text-align: center;
}

.pagination > li > input::-webkit-outer-spin-button,
.pagination > li > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pagination > li > input[type=number] {
    -moz-appearance: textfield;
}

.pagination-error > li {
  list-style: none;
}

.fa-lg {
  font-size: 1.4em !important;
}

/**
 * Fix for long url
 */
.break-line {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}


/*****
Event response part
*****/

.confirm-container {
  padding: 10px 30px 10px 30px;
}

.confirm-container .col-lg-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.confirm-event-type {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  line-height: 37px;
}

.confirm-event-title {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 37px;
  padding: 10px 30px 10px 30px;
  text-align: center;
  color: #000000;
}

.confirm-event-content {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.confirm-event-content p {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  color: #000000;
}

.confirm-event-buttons {
  padding-left: 10px;
  padding-right: 10px;
}

.confirm-event-buttons .col-lg-8 {
  padding: 5px;
}

.confirm-event-buttons a {
  text-align: center;
  color: #2d6ad4;
  padding: 15px;
  display: block;
  border: 1px solid #2d6ad4;
  text-transform: uppercase;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.confirm-event-buttons a:hover {
  background-color: #2d6ad4;
  color: #fff;
  cursor: pointer;
}

.event-mailing-card {
  text-align: left;
  font-style: normal;
}

.section--header {
  border-bottom: 2px solid #be1922;
}

.section-block {
  padding: 10px 0;
}

.section-img img {
  display: block;
  margin: auto;
}

.calendar-link-dropdown-container {
  position: relative;
  margin-top: 5px;
}

#calendar-link-dropdown {
  position: absolute;
  width: 100%;
  background-color: #F6F7FD;
  border: 1px solid #2d6ad4;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .08);
          box-shadow: 0 2px 10px rgba(0, 0, 0, .08);
  opacity: 0.0001;;
  -webkit-transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);;
  transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
  -webkit-transform: scale(0.6) translateY(-20%);
      -ms-transform: scale(0.6) translateY(-20%);
          transform: scale(0.6) translateY(-20%);
  z-index: 1;
}

.calendar-link-dropdown-active {
  opacity: 0.9999 !important;
  -webkit-transform: none !important;
      -ms-transform: none !important;
          transform: none !important;
}

#calendar-link-dropdown div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
}

#calendar-link-dropdown a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: none;
}

#calendar-link-dropdown a p {
  margin: 3px 0 0 15px;
}

.password-constraints-list {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 50px;
}

.password-constraint-met {
  color: #DEDEDE;
}

.toggle-password-visibility {
  position: relative;
}

#toggle_password_icon_eye {
  position: absolute;
  top: 0;
  right: 5px;
  height: 100%;
  width: 50px;
  padding: 15px;
  border-left: 1px solid #e4eaec;
}

.credential-expiration-delay {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.credential-expiration-delay p {
  margin-left: 10px;
}
