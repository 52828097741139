.cm-s-cobalt span.cm-atom {
  color: #ffee80;
}

.cm-s-cobalt .cm-qualifier {
  color: #ffee80;
}

.cm-s-cobalt .cm-property {
  color: #7CFFBB;
}

.cm-s-cobalt span.cm-keyword,
.cm-s-cobalt span.cm-number {
  color: #FF5E80;
}

.cm-s-cobalt .CodeMirror-gutters {
  border-color: #1A2C3F;
}

.cm-s-cobalt .cm-trailingspace {
  text-decoration: underline;
  text-decoration-color: #f00;
  text-decoration-style: dotted;
}

.cm-s-cobalt .cm-whitespace:before {
  color: #324759;
}

.CodeMirror-code > div > pre > span::after,
.CodeMirror-line > span::after {
  display: none;
}

.CodeMirror-foldmarker {
  background-color: #FFDF05;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  color: #806F0A;
  line-height: .3;
  cursor: pointer;
  margin-left: 0.3em;
  padding: 0 .3em;
  text-shadow: none;
}
